// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Feather from 'feather-icons'
window.Feather = Feather

Feather.replace()

import header from './components/header'
import footer from './components/footer'

const NUGlobalElements = {
  header,
  footer,
}

window.NUGlobalElements = NUGlobalElements

export default NUGlobalElements
